import React from "react";

const Footer = () => {
  return (
    <div className="bg_danger py-4">
      <div className="container">
        <div className="d-md-flex align-items-center justify-content-between">
          <p className="mb-0 ff text-white fs_18">
            For any support, contact at{" "}
            <a className="text-white td" href="mailTo:chris@drunkmates.com">
              chris@drunkmates.com
            </a>
          </p>
          <ul className="ps-0 mt-3 mt-md-0 mb-0 d-flex align-items-center  gap-5">
            <li>
              <a className="fs_18 ff text-white" href="#">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="fs_18 ff text-white" href="#">
                T&C
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
